<template>
  <base-modal
    modal-title="Confirm Message"
    @hidden="emitRejectedEvent()"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div v-if="statusMode" class="text-sm mb-3 text-center">
          Are you sure to {{statusMode}} the selected collaboration(s)?
        </div>
        <div v-else class="text-sm mb-3 text-center">
          Are you sure to end this collaboration?
        </div>
        <div class="text-center">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="emitAcceptedEvent()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="emitRejectedEvent()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'ConfirmEndCollaborationModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
      statusMode: {
          type: String,
          default: '',
          description: 'EMPTY, "end" or "delete"'
      }
    },

    emits: [
        'accepted',
        'rejected'
    ],

    setup (props, { emit }) {
        const emitAcceptedEvent = () => {
            emit('accepted');
        };
        const emitRejectedEvent = () => {
            emit('rejected');
        };

        return {
            emitAcceptedEvent,
            emitRejectedEvent
        };
    }
};
</script>
